import request from '@/common/utils/request'
import Vue from "vue";

export function getList(data) {
  return request({
    url: Vue.prototype.$baseUrl + '/adminapi/activityInfo/listActivityInfo',
    method: 'post',
    data
  })
}
export function doEdit(data) {
    return request({
      url: Vue.prototype.$baseUrl + '/adminapi/yqchengnuo/update',
      method: 'post',
      data
    })
  }
  export function shenhe(data) {//对参会人员审核
    return request({
      url: Vue.prototype.$baseUrl + '/adminapi/activityInfo/processActivityInfo',
      method: 'post',
      data
    })
}
export function updateActivityInfo(data) {//对参会人员编辑
  return request({
    url: Vue.prototype.$baseUrl + '/adminapi/activityInfo/updateActivityInfo',
    method: 'post',
    data
  })
}
export function saveActivityInfo(data) {//对参会人员待上穿
  return request({
    url: Vue.prototype.$baseUrl + '/adminapi/activityInfo/saveActivityInfo',
    method: 'post',
    data
  })
}
export function getrenwuList(params) {//对参会人员待上穿
  return request({
    url: Vue.prototype.$baseUrl + '/adminapi/activityInfo/activityByParticipateId',
    method: 'get',
    params
  })
}
