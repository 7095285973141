<!-- xuwencheng  2022年6年25日11:00  这是我的活动添加列表 -->
<!-- 编辑 -->
<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="65%"
    @close="close"
  >
    <el-form ref="form" label-width="200px" :model="form" :rules="rules">
      <el-form-item label="姓名" prop="yname">
        <el-input v-model="form.yname" disabled placeholder="请输入讲师姓名" />
      </el-form-item>
      <el-form-item label="嘉宾类型" prop="userTypeName">
        <el-input
          v-model="form.userTypeName"
          disabled
          placeholder="请输入讲师姓名"
        />
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="form.phone" disabled placeholder="请输入手机号" />
      </el-form-item>

      <el-form-item label="身份证" prop="idcard">
        <el-input v-model="form.idcard" disabled />
      </el-form-item>
      <el-form-item label="需要完成的任务" prop="activityName">
        <el-input
          disabled
          v-model="form.activityName"
          placeholder="请输入健康码"
        />
      </el-form-item>
      <el-form-item label="健康码图片" prop="healthImg">
        <el-upload
          class="avatar-uploader"
          :headers="token"
          action="https://ybh2022.gengduoke.com/adminapi/uploadToQiNiu"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="imageUrltwo" :src="imageUrltwo" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="出发地" prop="startAddress">
        <div class="block">
          <el-cascader
            v-model="startAddress"
            ref="cascader"
            filterable
            :options="optionswo"
            placeholder="试试搜索：指南"
            @change="cascar"
          />
        </div>
      </el-form-item>
      <el-form-item label="温度" prop="wendu">
        <el-input class="el-input" v-model="form.wendu" />
      </el-form-item>
      <el-form-item label="七日旅居史" prop="taddress">
        <el-input v-model="form.taddress" placeholder="请输入七日旅居史" />
      </el-form-item>

      <el-form-item label="行程码图片" prop="cuserId">
        <el-upload
          class="avatar-uploader"
          :headers="token"
          action="https://ybh2022.gengduoke.com/adminapi/uploadToQiNiu"
          :show-file-list="false"
          :on-success="hand"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="imageUrlthree" :src="imageUrlthree" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="手写签名图片" prop="cuserId">
        <el-upload
          :headers="token"
          class="avatar-uploader"
          action="https://ybh2022.gengduoke.com/adminapi/uploadToQiNiu"
          :show-file-list="false"
          :on-success="handS"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="imageUrlthreeS" :src="imageUrlthreeS" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <!-- <el-form-item
        label="7天内是否去过或接触过发生疫情的地级市和有扩散风险的毗邻地区及人员:"
        prop="jcs14"
      >
        <el-input
          v-model="form.jcs14"
          placeholder="请输入七日途经7天内是否去过或接触过发生疫情的地级市和有扩散风险的毗邻地区及人员地"
        />
      </el-form-item> -->
      <el-form-item label="疫苗接种情况" prop="ymStatus">
        <!-- <el-input v-model="form.ymStatus" placeholder="请输入疫苗接种情况" /> -->

        <span v-if="form.ymStatus == 0" style="color: red">未接种</span>

        <span v-else-if="form.ymStatus == 1" style="color: gray">接种中</span>

        <span v-else style="color: green">已接种完毕</span>
      </el-form-item>
      <el-form-item label="会议报道日前7天症状" prop="yzh">
        <el-input v-model="form.yzh" placeholder="请输入会议报道日前7天症状" />
      </el-form-item>

      <!-- <el-form-item> -->
    </el-form>

    <el-button class="baocun" size="medium" type="primary" @click="save">
      保存
    </el-button>
  </el-dialog>

  <!-- 增加 -->
</template>
  
  <script>
/* eslint-disable*/

import { updateActivityInfo } from "@/api/yiqing";
import axios from "axios";
import { getToken } from "../../../common/utils/auth";
import { city } from "./js/city";

// import fuwenben from "@/views/vab/editor/wang";
//import { number } from 'echarts'
export default {
  name: "search",
  components: {
    // fuwenben,
  },
  data() {
    const validatesurfacePlot = (rule, value, callback) => {
      console.log(this.form.headPortrait);
      if (this.form.headPortrait === "") {
        callback(new Error("请添加图片!!!!"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var contactPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    return {
      startAddress: "",
      optionswo: city,

      dialogImageUrl: "",
      dialogVisible: false,
      imageUrltwo: "",
      imageUrlthree: "",
      imageUrlthreeS: "", //手写签名找

      title: "",
      dialogFormVisible: false,
      options: [],
      value: [],
      list: [],
      loading: false,
      optionstwo: [],
      valuetwo: [],
      listtwo: [],
      loadingtwo: false,
      fileUrl: "", //视频url
      token: "",
      imageUrl: "",
      formData: {
        key: "",
        policy: "",
        OSSAccessKeyId: "",
        signature: "",
      },
      ruleForm: {
        checkPass: "",
      },
      videoForm: {
        Video: "",
      },
      options2: [],
      options3: [],
      aliyunform: {},
      fuwenben: "",
      form: {},
      activated() {
        if (this.$refs.MaskVideo.src) this.$refs.MaskVideo.play();
      },
      deactivated() {
        if (this.$refs.MaskVideo.src) this.$refs.MaskVideo.pause();
      },

      rules: {},
    };
  },
  created() {
    let t = getToken();
    this.token = { "x-token": t };
    console.log(this.token);
  },
  // mounted() {
  //   this.list = this.states.map((item) => {
  //     return { value: `value:${item}`, label: `label:${item}` }
  //   })
  //   console.log(this.list)
  // },
  methods: {
    cascar(e) {
      let that = this;
      that.$set(
        that.form,
        "startAddress",
        that.$refs["cascader"].getCheckedNodes()[0].pathLabels[0] +
          "/" +
          that.$refs["cascader"].getCheckedNodes()[0].pathLabels[1] +
          "/" +
          that.$refs["cascader"].getCheckedNodes()[0].pathLabels[2]
      );
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    hand(res, file, fileList) {
      //上传行程吗
      this.imageUrlthree = URL.createObjectURL(file.raw);
      console.log(res);
      this.form.journeyImg = res.data;
    },
    handleAvatarSuccess(res, file, fileList) {
      //健康码
      this.imageUrltwo = URL.createObjectURL(file.raw);
      console.log(res);
      this.form.healthImg = res.data;
    },
    handS(res, file, fileList) {
      //上传手写签名
      this.imageUrlthreeS = URL.createObjectURL(file.raw);
      console.log(res);
      this.form.singature = res.data;
    },
    handleAvatarSuccesstwo(res, file, fileList) {
      this.imageUrltwo = URL.createObjectURL(file.raw);
      console.log(res);
      this.form.surfacePlot = res.data;
    },
    handlePictureCardPreview(file) {
      this.imageUrltwo = file.url;
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        const msg = getcurid({
          keyWord: query,
        });
        msg.then((res) => {
          this.list = res.data.map((item) => {
            return {
              value: `${item.unionid}`,
              label: `${item.unionid}`,
            };
          });
        });
        setTimeout(() => {
          this.loading = false;
          this.options = this.list.filter((item) => {
            console.log(item);
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    remoteMethodtwo(query) {
      if (query !== "") {
        this.loadingtwo = true;
        const msg = getcurid({
          keyWord: query,
        });
        msg.then((res) => {
          this.listtwo = res.data.map((item) => {
            return {
              value: `${item.cuserId}`,
              label: `${item.cuserId}`,
            };
          });
        });
        setTimeout(() => {
          this.loadingtwo = false;
          this.optionstwo = this.listtwo.filter((item) => {
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.optionstwo = [];
      }
    },
    handleChange(file, fileList) {
      this.formData.key = this.aliyunform.dir + "/" + file.name;
      this.formData.policy = this.aliyunform.policy;
      this.formData.OSSAccessKeyId = this.aliyunform.accessid;
      this.formData.signature = this.aliyunform.signature;
    },
    handlePreview(file) {
      console.log(file);
    },

    handleAvatarSuccessone(res, file, fileList) {
      this.imageUrl = URL.createObjectURL(file.raw);
      console.log(res);
      this.form.headPortrait = res.data;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return true;
    },
    showEdit(row) {
      if (!row) {
        this.title = "添加";
      } else {
        this.title = "编辑";
        this.form = Object.assign({}, row);
        // this.startAddress = row.startAddress;

        console.log(typeof this.startAddress);
        this.imageUrltwo = row.healthImg;
        this.form.userId = row.userId;
        this.imageUrl = row.imgUrl;
        this.imageUrlthree = row.journeyImg;
        this.dialogFormVisible = true;
        this.imageUrlthreeS = row.singature;
        this.form.articleContent = row.articleContent;
      }
    },
    close() {
      let userId = this.form.userId;
      this.$refs["form"].resetFields();
      this.form = this.$options.data().form;
      if (!this.form.id) {
        this.form.userId = userId;
      }
      console.log(this.form);
      this.dialogFormVisible = false;
      this.imageUrl = "";
      // this.form = ''
    },
    save() {
      // this.$router.push('/other/workflow')

      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          if (this.form.id) {
            let func = updateActivityInfo;
            func(this.form)
              .then((res) => {
                console.log(res);
                let title = "修改";

                if (res.code == 0) {
                  this.$alert(res.msg, title + "失败", {
                    dangerouslyUseHTMLString: true,
                    type: "warning",
                  });
                } else if (res.code == 1) {
                  this.$alert(res.msg, title + "成功", {
                    dangerouslyUseHTMLString: true,
                    type: "success",
                  }).then(() => {
                    this.dialogFormVisible = false;
                    this.$emit("fetch-data");
                  });
                }
              })
              .catch((res) => {
                console.log(res);
              });
          } else {
            this.form.cuserId = this.form.cuserId + 0;
            const msg = doAdd(this.form);
            console.log(this.form);
            msg.then((res) => {
              console.log(res.msg);
              if (res.msg === "新增成功") {
                this.$baseMessage(
                  res.msg,
                  "success",
                  "vab-hey-message-success"
                );
                this.$emit("fetch-data");
                this.close();
                this.$router.push({
                  path: "/huodong/huodonglist",
                });
              }
            });
          }
        }
      });
    },
    async fetchDatathree() {
      let that = this;
      let queryForm = {};
      const res = await AssignEmployees(queryForm);
      console.log(res.data.list);
      this.options3 = res.data.list;
      if (!this.form.id) {
        this.form.userId = this.options3.filter(
          (item) => item.nickName === res.data.nickName
        )[0].id;
      }

      console.log(res.data.nickName);
    },
    async fetchData() {
      let queryForm = {};
      const res = await getListtwo(queryForm);

      this.options2 = res.articleCategoryList;
    },

    nickNameChange() {
      this.$forceUpdate();
    },
  },
};
</script>
  <style>
/* .huakuai {
      position: relative;
      left: 96%;
    } */
.avatar-uploader .el-upload {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  width: 178px;
  height: 178px;
  font-size: 28px;
  line-height: 178px;
  color: #8c939d;
  text-align: center;
}
.avatar {
  display: block;
  width: 178px;
  height: 178px;
}
/* .videssad {
      display: block;
      width: 360px;
      height: 178px;
    } */
.el-upload-dragger {
  position: relative;
  box-sizing: border-box;
  width: 180px;
  height: 180px;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}
.baocun {
  width: 100%;
  height: 100%;
}
</style>
  