<template>
  <el-dialog
    append-to-body
    :title="title"
    :visible.sync="dialogFormVisible"
    width="70%"
    @close="close"
  >
    <el-row>
      <el-col :span="24">
        <div class="search-box">
          <el-form :inline="true" size="medium">
            <el-form-item>
              <!-- <el-button type="primary" size="small" @click="showAddDialogForm">新增任务</el-button> -->
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>
    <el-table
      :data="tableData"
      :header-cell-style="{ backgroundColor: '#f4f4f5', textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
      border
      style="width: 100%"
    >
      <el-table-column prop="id" label="序号" type="index" width="80">
      </el-table-column>

      <el-table-column prop="username" label="姓名"> </el-table-column>
      <el-table-column prop="company" label="单位"> </el-table-column>
      <el-table-column prop="post" label="职务"> </el-table-column>

      <el-table-column show-overflow-tooltip prop="postRank" label="职级名称">
      </el-table-column>

      <el-table-column
        align="center"
        prop="status"
        label="参会状态"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <template v-if="scope.row.status == -1">取消</template>
          <template v-else-if="scope.row.status == 0">审核中</template>
          <template v-else-if="scope.row.status == 1">审核通过</template>
          <template v-else-if="scope.row.status == 2">暂不确定</template>
          <template v-else-if="scope.row.status == 3">确认参加</template>

          <template v-else-if="scope.row.status == -2">嘉宾拒绝</template>
          <template v-else-if="scope.row.status == -3">删除嘉宾 </template>
          <template v-else-if="scope.row.status == -9">暂存 </template>
        </template>
      </el-table-column>
      <!-- 1 政务贵宾 
2 政务嘉宾 
3 行业贵宾 
4 行业嘉宾 
5 仅参加开幕式 
6 贵宾随行人员 
7 媒体记者 
8 参展商 
9 观众 
10 工作人员  -->
      <el-table-column
        align="center"
        label="用户身份类型"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <template v-if="scope.row.userTypeId == 1">
            <span>政务贵宾 </span>
          </template>
          <template v-else-if="scope.row.userTypeId == 2">
            <span>政务嘉宾 </span>
          </template>
          <template v-else-if="scope.row.userTypeId == 3">
            <span>行业贵宾 </span>
          </template>
          <template v-else-if="scope.row.userTypeId == 4">
            <span>行业嘉宾 </span>
          </template>
          <template v-else-if="scope.row.userTypeId == 5">
            <span>仅参加开幕式 </span>
          </template>
          <template v-else-if="scope.row.userTypeId == 6">
            <span>贵宾随行人员 </span>
          </template>
          <template v-else-if="scope.row.userTypeId == 7">
            <span>媒体记者 </span>
          </template>
          <template v-else-if="scope.row.userTypeId == 8">
            <span>参展商 </span>
          </template>
          <template v-else-if="scope.row.userTypeId == 10">
            <span>工作人员 </span>
          </template>
          <template v-else-if="scope.row.userTypeId == 9">
            <span>观众 </span>
          </template>
        </template>
      </el-table-column>

      <el-table-column show-overflow-tooltip label="会议报名状态">
        <!-- 状态 0未审核 1通过 -1未通过 2已参加 -->
        <template slot-scope="scope">
          <template v-if="scope.row.checkStatus == 1">
            <span>通过 </span>
          </template>
          <template v-else-if="scope.row.checkStatus == -1">
            <span>未通过 </span>
          </template>
          <template v-else-if="scope.row.checkStatus == 2">
            <span>已参加 </span>
          </template>
          <template v-else-if="scope.row.checkStatus == 0">
            <span>未审核 </span>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        prop="address"
        label="操作"
        fixed="right"
        min-width="200"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleEdit(scope.$index, scope.row)"
            >审核</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-row>
      <el-col :span="24" class="pagination-box">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </el-col>
    </el-row>
    <el-dialog
      title="excel导入"
      :visible.sync="excelImp.dialogVisible"
      width="60%"
      :close-on-click-modal="false"
    >
      <el-form>
        <el-row>
          <el-col :span="24">
            <a :href="baseUrl + '/YQhuiyiqian.xlsx'" target="_blank"
              >点击下载导入模板</a
            >
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-col :span="24">
            <el-upload
              class="upload-demo"
              ref="upload"
              name="file"
              :action="excelImp.action"
              :multiple="false"
              :limit="1"
              accept=".xls,.xlsx"
              :file-list="excelImp.fileList"
              :auto-upload="false"
              :on-success="onUploadSuccess"
              :on-error="onUploadError"
            >
              <el-button slot="trigger" size="small" type="primary"
                >选取文件</el-button
              >
              <!--              <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>-->
              <div slot="tip" class="el-upload__tip">只能上传xls/xlsx</div>
            </el-upload>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="excelImp.dialogVisible = false">关 闭</el-button>
        <el-button
          type="primary"
          @click.native.prevent="submitUpload"
          :loading="excelImp.loading"
          >立即导入</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      append-to-body
      title="审核"
      :visible.sync="editFormVisible"
      width="500px"
      :close-on-click-modal="false"
    >
      <el-form
        ref="editForm"
        label-width="100px"
        :model="editForm"
        :rules="rules"
      >
        <el-form-item label="当前状态为">
          <!-- 状态 0未审核 1通过 -1未通过 2已参加 -->
          <span style="color: gray" v-if="actorScore == 0">未审核</span>
          <span style="color: green" v-else-if="actorScore == 1">通过</span>
          <span style="color: red" v-else-if="actorScore == -1">未通过</span>
          <span style="color: yellow" v-else-if="actorScore == 2">已参加</span>
          <!-- <span style="color: red" v-else-if="actorScore == 4">审核失败</span> -->
        </el-form-item>

        <el-form-item label="选择状态" prop="chekcValue">
          <el-select
            v-model="editForm.chekcValue"
            clearable
            placeholder="请选择审核状态"
          >
            <el-option
              v-for="item in options1"
              :key="item.id"
              :label="item.creClasName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click.native.prevent="submitForm"
          :loading="loading"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <edits ref="edit" @fetch-data="getDataList" />
  </el-dialog>
</template>
                
                <script>
import { update } from "@/api/bumen";
import axios from "axios";

import { getUserTypeList } from "@/api/user_type";
import { getYycompanyList } from "@/api/yy_company";
import { getHotelList } from "@/api/hotel";
import { getAllUserList } from "@/api/user";
import { getPlateListWithGroup } from "@/api/plate";
import { getParticipateListwo } from "@/api/bumen";
import { getToken } from "@/common/utils/auth";
import { getrenyuanList, shenhe } from "@/api/danyuan";
import Edits from "../../yiqing/model/edit.vue";

import util from "@/common/utils/util.js";
import { getglyList, DELETE } from "@/api/bumen";

export default {
  name: "renwuidyiqing",
  components: { Edits },

  data() {
    return {
      excelImp: {
        dialogVisible: false,
        loading: false,
        fileList: [],
        action: "",
        loadingDiv: "",
      },
      handlerUserList: [],
      userList: [],
      userTypeList: [],
      yyCompanyList: [],
      tableData: [],
      total: 0,
      tableHeight: 200,
      editFormVisible: false,

      loading: false,
      options1: [
        {
          id: 1,
          creClasName: "审核通过",
        },
        {
          id: -1,
          creClasName: "审核不通过",
        },
      ], //状态
      baseUrl: "",
      pageSize: 10,
      editForm: {
        plateId: "",
      },
      actorScore: "", //当前状态
      actorScorewo: "", //修改后的分数分数
      form: {
        preUserType: "", //变更前
        aftUserType: "", //变更后
        id: "", //当前项的id
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入部门名称",
            trigger: "blur",
          },
        ],
      },
      searchForm: {
        pageSize: 10,
        pageNum: 1,
        plate: "",
      },

      title: "",
      dialogFormVisible: false,
    };
  },
  created() {},
  methods: {
    exportExcelSameName() {
      const loadingDiv = util.showLoading("正在导出EXCEL");

      let t = getToken();
      let headers = { "x-token": t };
      axios({
        method: "post",
        url: this.$adminApiUrl + "/activityInfo/download", // 请求地址
        headers: headers,

        responseType: "blob", // 表明返回服务器返回的数据类型
      })
        .then((res) => {
          loadingDiv.close();
          let blob = new Blob([res.data], { type: "application/xlsx" });
          let url = window.URL.createObjectURL(blob);
          const link = document.createElement("a"); // 创建a标签
          link.href = url;
          let timeStr = this.fmtDate(new Date(), "yyyyMMdd_hhmmss");

          let fileName = "表";

          link.download = fileName + "_" + timeStr + ".xlsx"; // 重命名文件
          link.click();
          URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err);
          loadingDiv.close();
          this.$alert("导出失败,请检查网络", {
            dangerouslyUseHTMLString: true,
            type: "warning",
          });
        });
    },
    search() {
      this.getDataList();
    },
    submitForm() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let loadingText = "审核中...";

          const loadingDiv = util.showLoading(loadingText);
          let func = shenhe;

          func(this.editForm)
            .then((res) => {
              let title = "审核";

              if (res.code == 0) {
                this.$alert(res.msg, title + "失败", {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
              } else if (res.code == 1) {
                this.$alert(res.msg, title + "成功", {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then(() => {
                  this.editFormVisible = false;
                  this.editForm = Object.assign({});
                  this.getDataList();
                });
              }
              this.loading = false;
              loadingDiv.close();
            })
            .catch((res) => {
              this.loading = false;
              loadingDiv.close();
            });
        }
      });
    },
    handleEdit(index, row) {
      this.actorScore = row.checkStatus;
      this.editFormVisible = true;
      this.editForm.id = row.id;
      this.editForm.pid = row.pid;
      // this.editForm.process=row.process
    },
    datatimes() {
      this.editForm.endTime = this.value1 / 1000;
      console.log(this.editForm.endTime);
    },
    handleExcelImp() {
      this.excelImp.fileList = [];
      this.excelImp.dialogVisible = true;
    },
    getDataListwo() {
      const loadingDiv = util.showLoading();

      getParticipateListwo()
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 1) {
            this.handlerUserList = res.data;
            console.log(res);
          }
          loadingDiv.close();
        })
        .catch((res) => {
          loadingDiv.close();
        });
    },
    submitUpload() {
      this.excelImp.loading = true;
      this.excelImp.loadingDiv = util.showLoading("导入中");
      this.$refs.upload.submit();
    },
    onUploadError(err, file, fileList) {
      this.excelImp.loading = false;
      this.excelImp.loadingDiv.close();
      this.$alert("导入失败", {
        dangerouslyUseHTMLString: true,
        type: "warning",
      });
    },
    onUploadSuccess(res, file, fileList) {
      this.excelImp.loading = false;
      this.excelImp.loadingDiv.close();

      if (res.code == 0) {
        this.$alert(res.msg, {
          dangerouslyUseHTMLString: true,
          type: "warning",
        });
      } else if (res.code == 1) {
        this.$alert(res.msg, {
          dangerouslyUseHTMLString: true,
          type: "success",
        }).then(() => {
          this.getDataList();
          this.excelImp.dialogVisible = false;
        });
      }
    },
    handleExcelImp() {
      this.excelImp.fileList = [];
      this.excelImp.dialogVisible = true;
    },
    init() {
      getUserTypeList().then((res) => {
        if (res.code) {
          let userTypeList = [];
          // userTypeList.push({ name: "请选择嘉宾类型", id: "" });
          // this.userTypeList = res.data;
          // this.userTypeList.unshift({name: "请选择嘉宾类型", id:''})
          for (let i in res.data) {
            if (
              res.data[i].id == 1 ||
              res.data[i].id == 2 ||
              res.data[i].id == 3 ||
              res.data[i].id == 4 ||
              res.data[i].id == 5 ||
              res.data[i].id == 6
            ) {
              userTypeList.push(res.data[i]);
            }
          }

          this.userTypeList = userTypeList;
        }
      });
      getYycompanyList().then((res) => {
        if (res.code) {
          this.yyCompanyList = res.data;
          this.yyCompanyList.unshift({ name: "请选择邀宾单位/部门", id: "" });
        }
      });

      getHotelList().then((res) => {
        if (res.code) {
          this.hotelList = res.data;
          this.hotelList.unshift({ id: "", name: "请选择酒店" });
        }
      });

      getAllUserList().then((res) => {
        if (res.code) {
          this.userList = JSON.parse(JSON.stringify(res.data));
          this.userList2 = JSON.parse(JSON.stringify(res.data));
        }
      });

      // 按照分组获取会议
      getPlateListWithGroup({ isZy: 0 }).then((res) => {
        this.plateWithGroupList = res.data;
        if (res.code) {
          this.tabsActiveName = res.data[0].title;
        }
      });
    },
    // 时间格式化
    dateFormattwo(row, column) {
      var date = row[column.property] * 1000;
      if (date == undefined) {
        return "";
      }
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    handleDelete(index, row) {
      let loadingDiv;
      this.$confirm("是否确认删除", "删除确认")
        .then((res) => {
          if ((res = "confirm")) {
            loadingDiv = util.showLoading("删除中...");
            return doDELETE({ id: row.id });
          }
        })
        .then((res) => {
          loadingDiv.close();
          if (res.code == 0) {
            this.$alert(res.msg, "删除失败", {
              dangerouslyUseHTMLString: true,
              type: "warning",
            });
          } else if (res.code == 1) {
            this.$alert(res.msg, "删除成功", {
              dangerouslyUseHTMLString: true,
              type: "success",
            }).then((res) => {
              this.editFormVisible = false;
              this.getDataList();
            });
          }
        })
        .catch((res) => {
          if (loadingDiv) {
            loadingDiv.close();
          }
        });
    },
    handleCurrentChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      this.getDataList();
    },
    handleSizeChange(val) {
      console.log(val);
      this.searchForm.pageSize = val;
      this.getDataList();
    },
    dateFormat(row, column) {
      var date = row[column.property];
      console.log(date);
      if (date == undefined) {
        return "";
      }
      return this.timestampToTime(date);
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + "-";
      let M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      let D = date.getDate() + " ";
      let h = date.getHours() + ":";
      let m = date.getMinutes() + ":";
      let s = date.getSeconds();
      return Y + M + D + h + m + s;
    },
    getDataList() {
      const loadingDiv = util.showLoading();

      getrenyuanList(this.searchForm)
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 1) {
            let data = res.data;

            this.total = data.total;
            this.tableData = data.dataList;
          }
          loadingDiv.close();
        })
        .catch((res) => {
          loadingDiv.close();
        });
    },
    handleChange() {
      this.show = false;
      setTimeout(() => {
        this.show = true;
      }, 300);
    },

    showEdit(row) {
      this.searchForm.plate = row.id;
      this.editForm.plateId = row.id; //会议id

      this.title = "查看当前单元下的人员列表";
      this.dialogFormVisible = true;
      this.getDataList();
    },
    close() {
      this.dialogFormVisible = false;
    },
  },
};
</script>
  <style scoped lang="scss">
// @import "~@/common/styles/element-variables.scss";
.search-box {
  background-color: #ffffff;
  min-height: 36px;
  line-height: 36px;
  padding-top: 2px;
  padding-bottom: 6px;
  .el-form-item {
    margin-bottom: 0px !important;
  }
  .el-form--inline {
    display: inline-block;
  }
}
.pagination-box {
  text-align: left;
  margin-top: 10px;
}
</style>
    <style lang="scss">
.el-dialog__header {
}
.el-dialog__body {
  padding-top: 10px;
  padding-bottom: 0px;
}
.el-dialog__footer {
  padding-top: 0px;
}
.fengmiantu {
  width: 50px;
  height: 50px;
}
</style>          