import request from '@/common/utils/request'
import Vue from "vue";



export function getList(data) {
    return request({
      url: Vue.prototype.$baseUrl + '/adminapi/plate/plate-list-subject',
      method: 'post',
      data
    })
}

export function getrenyuanList(data) {//当前单元下的人员列表
  return request({
    url: Vue.prototype.$baseUrl + '/adminapi/plate/plate-list-apply',
    method: 'post',
    data
  })
}
export function shenhe(data) {//当前单元下的人员列表的审核
  return request({
    url: Vue.prototype.$baseUrl + '/adminapi/participate/subscribe-check',
    method: 'post',
    data
  })
}
