import request from '@/common/utils/request'
import Vue from "vue";



export function add(data) {
  return request({
    url: Vue.prototype.$baseUrl + '/adminapi/workgroup/saveWorkGroup',
    method: 'post',
    data
  })
}
export function getList(params) {
    return request({
      url: Vue.prototype.$baseUrl + '/adminapi/workgroup/listWorkGroup',
      method: 'get',
      params
    })
}
export function doDELETE(data) {
    return request({
      url: Vue.prototype.$cmsBaseUrl + '/adminapi/workgroup/deleteWorkGroup',
      method: 'post',
      data
    })
}
export function DELETE(data) {//部门删除管理员
  return request({
    url: Vue.prototype.$cmsBaseUrl + '/adminapi/workgroupsubject/deleteWorkGroupSubject',
    method: 'post',
    data
  })
}
export function update(data) {
  return request({
    url: Vue.prototype.$cmsBaseUrl + '/adminapi/workgroup/updateWorkGroup',
    method: 'post',
    data
  })
}

export function getParticipateList(params) {//获取所有部门
  return request({
    url: Vue.prototype.$baseUrl + '/adminapi/workgroup/workGroupIdAndName',
    method: 'get',
    params
  })
}
export function getParticipateListwo(params) {//返回疫情防疫联络人角色的用户
  return request({
    url: Vue.prototype.$baseUrl + '/adminapi/user/allEpidemic',
    method: 'get',
    params
  })
}
export function genggai(data) {//嘉宾绑定部门
  return request({
    url: Vue.prototype.$baseUrl + '/adminapi/participate/participateAssociationWorkGroup',
    method: 'post',
    data
  })
}
export function genggaitwo(data) {//部门绑定疫情管理元
  return request({
    url: Vue.prototype.$baseUrl + '/adminapi/workgroupsubject/saveWorkGroupSubject',
    method: 'post',
    data
  })
}
export function getglyList(params) {//部门id查询绑定的管理员
  return request({
    url: Vue.prototype.$baseUrl + '/adminapi/workgroup/listUserByWorkGroupId',
    method: 'get',
    params
  })
}
export function getParticipateListhree(params) {//查询所有任务
  return request({
    url: Vue.prototype.$baseUrl + '/adminapi/activity/allActivityIdAndName',
    method: 'get',
    params
  })
}
